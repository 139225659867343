<template>
	<div class="flex justify-center">
		<div class="p-3 max-w-lg flex-auto">
			<h1 class="mb-5">Onboarding {{ account }}</h1>
			<div class="border-solid border border-gray-200 rounded p-5">
				<component
					:is="activeComponent.component"
					@next="nextStep"
					@prev="prevStep"
					@update:onboardingData="updateData"
					:onboardingData="onboardingData"
					:rules="rules"
				></component>
				<div class="mt-12">
					<button
						@click="nextStep"
						class="bg-indigo-500 text-white hover:bg-indigo-600 py-2 px-5 rounded w-full"
						:class="{ 'opacity-50': loading }"
						:disabled="loading"
					>
						<span v-if="!loading"> {{ steps[step].ctaText ?? 'Continue' }} </span>
						<span v-else>Loading...</span>
					</button>
				</div>
				<div v-if="step > 0">
					<button @click="prevStep" class="bg-transparent text-indigo-500 font-semibold hover:text-indigo-600 py-2 px-4 w-full">
						Go back
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import OnboardingCountry from '../components/onboarding/OnboardingCountry.vue'
	import OnboardingContacts from '../components/onboarding/OnboardingContacts.vue'
	// import OnboardingLegalEntity from '../components/onboarding/OnboardingLegalEntity.vue'
	import OnboardingPersonal from '../components/onboarding/OnboardingPersonal.vue'
	import OnboardingReview from '../components/onboarding/OnboardingReview.vue'
	import OnboardingBanking from '../components/onboarding/OnboardingBanking.vue'
	import axios from 'axios'
	import { required, helpers } from '@vuelidate/validators'
	import { ref, computed, onMounted } from 'vue'
	import { useRoute } from 'vue-router'

	onMounted(() => {
		fetchSession()
	})

	const step = ref(0)
	const loading = ref(false)
	const route = useRoute()
	const account = ref(null)

	const steps = [
		{
			description: 'Enter your email',
			component: OnboardingContacts,
		},
		{
			description: 'Enter your name',
			component: OnboardingCountry,
		},
		{
			description: '',
			component: OnboardingPersonal,
		},
		// {
		// 	description: 'Enter your password',
		// 	component: OnboardingLegalEntity,
		// },
		{
			description: 'Enter your bank account',
			component: OnboardingBanking,
		},
		{
			description: '',
			component: OnboardingReview,
			ctaText: 'Agree and submit',
		},
	]

	async function fetchSession() {
		// Fetch session data
		const sessionId = route.params.session_id
		try {
			const response = await axios.get(`/api/v1/accounts/onboarding/${sessionId}`)
			onboardingData.value = response.data
			account.value = response.data.account
		} catch (error) {
			console.error('Error fetching session data')
		}
	}

	const onboardingData = ref({
		business_profile: {
			url: 'https://stan.store/pupulated-via-api',
			name: 'Populated via API',
			mcc: '5815',
			type: 'individual',
		},
		individual: {
			email: null,
			phone: null,
			first_name: null,
			last_name: null,
			date_of_birth: null,
			address: {
				line1: null,
				line2: null,
				city: null,
				state: null,
				postal_code: null,
				country: null,
			},
			ssn: null,
		},
		bank_account: {
			account_holder_name: null,
			account_holder_type: 'individual',
			currency: 'usd',
			routing_number: null,
			account_number: null,
		},
	})

	async function submit() {
		try {
			loading.value = true
			const response = await axios.post('/api/v1/accounts', onboardingData.value)
			console.log(response.data)
			console.log('Account created')
		} catch (error) {
			console.log('Error creating account')
		} finally {
			loading.value = false
		}
	}

	const rules = {
		localData: {
			individual: {
				phone: {
					required,
					validFormat: helpers.regex('validFormat', /^\d{9}$/),
				},
				email: {
					required,
					emailFormat: helpers.regex('emailFormat', /^[^\s@]+@[^\s@]+\.[^\s@]+$/),
				},
			},
		},
	}

	const updateData = data => {
		onboardingData.value = { ...onboardingData.value, ...data }
	}

	const nextStep = () => {
		if (step.value === steps.length - 1) {
			submit()
			return
		} else {
			step.value = Math.min(step.value + 1, steps.length - 1)
		}
	}
	const prevStep = () => {
		step.value = Math.max(step.value - 1, 0)
	}

	const activeComponent = computed(() => steps[step.value])
</script>

<style lang="scss" scoped></style>
