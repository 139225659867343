<template>
	<!-- <CheckoutView /> -->
	<RouterView />
</template>

<script>
	export default {
		name: 'App',
	}
</script>

<style></style>
