<template>
	<div>
		<div class="">
			<label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state"> Country </label>
			<div class="relative">
				<select
					v-model="state.selectedCountry"
					@change="updateCountryCode"
					class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
					id="grid-state"
				>
					<option v-for="option in countryOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
				</select>
				<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
					<svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
						<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
					</svg>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { reactive, watch, defineEmits, defineProps, onMounted } from 'vue'
	import { useOnboarding } from './useOnboarding'

	onMounted(() => {
		// Initial call to set the default country code
		updateCountryCode()
	})

	const props = defineProps({
		onboardingData: {
			type: Object,
			required: true,
		},
		rules: {
			type: Object,
			required: true,
		},
	})

	const emits = defineEmits(['update:onboardingData'])
	const { localData } = useOnboarding(props, emits)

	const countryOptions = [
		{ value: 'US', label: 'United States' },
		{ value: 'CA', label: 'Canada' },
	]

	const state = reactive({
		selectedCountry: 'US', // Default to United States
	})

	function updateCountryCode() {
		localData.individual.address.country = state.selectedCountry
	}

	// Watch for changes in the selected country and update the country code
	watch(() => state.selectedCountry, updateCountryCode)
</script>

<style lang="scss" scoped></style>
