<template>
	<div>
		<div class="my-3 flex justify-end">
			<button @click="useDeafults" class="bg-white hover:bg-gray-100 text-gray-800 py-2 px-4 border border-gray-400 rounded shadow">
				Use Defaults
			</button>
		</div>
		<div class="mb-6">
			<label class="block text-gray-700 text-sm font-bold mb-2" for="mobile_number"> Mobile number </label>
			<input
				class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				id="mobile_number"
				type="text"
				placeholder="(506) 456-7892"
				v-model="localData.individual.phone"
			/>
			<p v-if="$v.localData.individual.phone.$invalid && $v.localData.individual.phone.$dirty" class="text-red-500 text-sm mt-1">
				Invalid phone number
			</p>
		</div>
		<div class="mb-4">
			<label class="block text-gray-700 text-sm font-bold mb-2" for="email"> Email </label>
			<input
				class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				id="email"
				type="text"
				placeholder="me@example.com"
				v-model="localData.individual.email"
			/>
		</div>
	</div>
</template>

<script setup>
	import { defineProps, defineEmits } from 'vue'
	import { useOnboarding } from './useOnboarding'

	const props = defineProps({
		onboardingData: {
			type: Object,
			required: true,
		},
		rules: {
			type: Object,
			required: true,
		},
	})

	const useDeafults = () => {
		localData.individual.phone = '5064567892'
		localData.individual.email = 'me@example.com'
	}

	const emits = defineEmits(['update:onboardingData'])

	const { localData, $v } = useOnboarding(props, emits)
</script>

<style lang="scss" scoped></style>
