import { createRouter, createWebHistory } from 'vue-router'
import CheckoutView from './views/CheckoutView.vue'
import DemoView from './views/DemoView.vue'
import OnboardingView from './views/OnboardingView.vue'

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'home',
			component: CheckoutView,
		},
		{
			path: '/demo',
			name: 'demo',
			component: DemoView,
		},
		{
			path: '/onboarding',
			name: 'onboarding',
			component: OnboardingView,
		},
		{
			path: '/onboarding/:session_id',
			name: 'Onboarding',
			component: OnboardingView,
			props: true, // This allows the session_id to be passed as a prop to the component
		},
	],
})

export default router
