import './assets/styles.css'

import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router' // Import the router

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

const app = createApp(App)

app.use(router).mount('#app')
