<template>
	<div>
		<div class="my-3 flex justify-end">
			<button @click="useDeafults" class="bg-white hover:bg-gray-100 text-gray-800 py-2 px-4 border border-gray-400 rounded shadow">
				Use Defaults
			</button>
		</div>
		<div class="mb-10">
			<label class="block text-gray-700 text-sm font-bold mb-2" for="mobile_number"> Account details </label>
			<input
				class="mb-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				id="account_holder_name"
				type="text"
				placeholder="Account Holder Name"
				v-model="localData.bank_account.account_holder_name"
			/>
			<input
				class="mb-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				id="address_2"
				type="text"
				placeholder="Country (US)"
				v-model="localData.bank_account.country"
			/>
			<input
				class="mb-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				id="address_2"
				type="text"
				placeholder="Currency (usd)"
				v-model="localData.bank_account.currency"
			/>
		</div>
		<div class="mb-10">
			<label class="block text-gray-700 text-sm font-bold mb-2" for="mobile_number"> Routing number </label>
			<input
				class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
				id="mobile_number"
				type="text"
				placeholder="110000000"
				v-model="localData.bank_account.routing_number"
			/>
		</div>
		<div class="mb-10">
			<label class="block text-gray-700 text-sm font-bold mb-2" for="email"> Account number </label>
			<input
				class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				id="date_of_birth"
				type="text"
				placeholder="000123456789"
				v-model="localData.bank_account.account_number"
			/>
		</div>
	</div>
</template>

<script setup>
	import { defineProps, defineEmits, onMounted } from 'vue'
	import { useOnboarding } from './useOnboarding'

	onMounted(() => {
		if (!localData.bank_account.account_holder_name) {
			generateAccountHolder()
		}
		if (!localData.bank_account.country) {
			generateAccountCountry()
		}
	})

	const generateAccountHolder = () => {
		if (!!localData.individual.first_name && !!localData.individual.last_name) {
			localData.bank_account.account_holder_name = `${localData.individual.first_name} ${localData.individual.last_name}`
		}
	}

	function generateAccountCountry() {
		if (localData.individual.address.country) {
			localData.bank_account.country = localData.individual.address.country
		}
	}

	const props = defineProps({
		onboardingData: {
			type: Object,
			required: true,
		},
		rules: {
			type: Object,
			required: true,
		},
	})

	const useDeafults = () => {
		localData.bank_account.account_holder_name = 'Jane Doe'
		localData.bank_account.country = 'US'
		localData.bank_account.currency = 'usd'
		localData.bank_account.routing_number = '110000000'
		localData.bank_account.account_number = '000123456789'
	}

	const emits = defineEmits(['update:onboardingData'])

	const { localData } = useOnboarding(props, emits)
</script>

<style lang="scss" scoped></style>
