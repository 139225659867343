<template>
	<div>
		<div class="my-3 flex justify-end">
			<button @click="useDefaults" class="bg-white hover:bg-gray-100 text-gray-800 py-2 px-4 border border-gray-400 rounded shadow">
				Use Defaults
			</button>
		</div>
		<div class="mb-10">
			<label class="block text-gray-700 text-sm font-bold mb-2" for="first_name">Your legal name</label>
			<input
				class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
				id="first_name"
				type="text"
				placeholder="First name"
				v-model="localData.individual.first_name"
			/>
			<input
				class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				id="last_name"
				type="text"
				placeholder="Last name"
				v-model="localData.individual.last_name"
			/>
		</div>
		<div class="mb-10">
			<label class="block text-gray-700 text-sm font-bold mb-2" for="date_of_birth">Date of birth</label>
			<input
				class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				id="date_of_birth"
				type="text"
				placeholder="MM/DD/YYYY"
				v-model="localData.individual.date_of_birth"
			/>
		</div>
		<div class="mb-10">
			<label class="block text-gray-700 text-sm font-bold mb-2" for="address">Home address</label>
			<input
				class="mb-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				id="address"
				type="text"
				placeholder="Street address"
				v-model="localData.individual.address.line1"
			/>
			<input
				class="mb-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				id="address_2"
				type="text"
				placeholder="Apartment, unit, or other"
				v-model="localData.individual.address.line2"
			/>
			<input
				class="mb-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				id="city"
				type="text"
				placeholder="City"
				v-model="localData.individual.address.city"
			/>
			<input
				class="mb-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				id="state"
				type="text"
				placeholder="State"
				v-model="localData.individual.address.state"
			/>
			<input
				class="mb-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				id="zip"
				type="text"
				placeholder="Zip code"
				v-model="localData.individual.address.postal_code"
			/>
		</div>
		<div class="mb-10">
			<label class="block text-gray-700 text-sm font-bold mb-2" for="ssn">Social Security number</label>
			<input
				class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				id="ssn"
				type="text"
				placeholder="123-45-6789"
				v-model="localData.individual.ssn"
			/>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'OnboardingPersonal',
	}
</script>

<script setup>
	import { defineProps, defineEmits } from 'vue'
	import { useOnboarding } from './useOnboarding'

	const props = defineProps({
		onboardingData: {
			type: Object,
			required: true,
		},
		rules: {
			type: Object,
			required: true,
		},
	})

	const useDefaults = () => {
		localData.individual.first_name = 'Jane'
		localData.individual.last_name = 'Doe'
		localData.individual.date_of_birth = '01/01/1998'
		localData.individual.address.line1 = 'address_full_match'
		localData.individual.address.line2 = 'Apt 1'
		localData.individual.address.city = 'Anytown'
		localData.individual.address.state = 'NY'
		localData.individual.address.postal_code = '12345'
		localData.individual.ssn = '000000000'
	}

	const emits = defineEmits(['update:onboardingData'])

	const { localData } = useOnboarding(props, emits)
</script>

<style lang="scss" scoped></style>
