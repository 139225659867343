// composables/useOnboarding.js
import { reactive, watch } from 'vue'
import useVuelidate from '@vuelidate/core'

export function useOnboarding(props, emits) {
	// Define props directly
	const onboardingData = reactive(props.onboardingData)
	const rules = props.rules

	// Create a local copy of the onboarding data
	const localData = reactive({ ...onboardingData })

	const $v = useVuelidate(rules, { localData })

	// Watch for changes in local data and emit updates to parent
	watch(
		() => localData,
		newVal => {
			emits('update:onboardingData', { ...newVal })
		},
		{ deep: true }
	)

	// Watch for changes in the props and update local data if necessary
	watch(
		() => props.onboardingData,
		newData => {
			if (newData !== localData) {
				Object.assign(localData, newData)
			}
		},
		{ deep: true }
	)

	return {
		localData,
		$v,
	}
}
